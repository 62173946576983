<template>
    <div class="main">
        <div class="bjtiao"></div>
        <div class="invoice" >
            <van-cell center >
            <div class="titleContext"><span class="title">{{getfplxmc(mes.data.InvoiceType)}}</span></div>

                <div class="invoiceContent">
                    <div class="qjListItem">
                        <span class="spanTitle">销方名称：</span>
                        <span>{{mes.data.MerchantName}}</span>
                    </div>
                    <div class="qjListItem">
                        <span class="spanTitle">购方名称：</span>
                        <span>{{mes.data.PurchaserName}}</span>
                    </div>
                    <div class="qjListItem">
                        <span class="spanTitle">发票金额：</span>
                        <div>{{mes.data.TotalAmount}}</div>
                    </div>
                    <div class="qjListItem">
                        <span class="spanTitle">发票状态：</span>
                        <div>{{getfpztByStatues(mes.data.State)}}</div>
                    </div>
                    <div class="qjListItem">
                        <span class="spanTitle">开具日期：</span>
                        <span>{{riqizh(mes.data.InvoiceTime)}}</span>
                    </div>
                    <div class="qjListItem">
                        <span class="spanTitle">发票代码：</span>
                        <span>{{mes.data.InvoiceCode}}</span>
                    </div>
                    <div class="qjListItem">
                        <span class="spanTitle">发票号码：</span>
                        <span>{{mes.data.InvoiceNum}}</span>
                    </div>
                    <div class="qjListItem">
                        <span class="spanTitle">交付手机：</span>
                        <span>{{mes.data.PurchaserMobile}}</span>
                    </div>
                    <div class="qjListItem">
                        <span class="spanTitle">交付邮箱：</span>
                        <span>{{mes.data.PurchaserEmail}}</span>
                    </div>
                </div>
                <div class="imgContent">
                    <img src="../../assets/img/kpsb@3x.png" v-if="mes.data.State=='10'"  style="width:140px;height:140px;"/>
                    <img src="../../assets/img/kpcg@3x.png" v-if="mes.data.State=='0' || mes.data.State=='1'" style="width:140px;height:140px;"/>
                </div>
            </van-cell>
        </div>

        <div class="btnContent">
            <van-button class="actionBtn" type="success" round  block @click="shuaxin" v-if="mes.data.State=='9' || mes.data.State=='10' || mes.data.State=='11' || mes.data.State=='12'">刷 新</van-button>
            <van-button class="actionBtn" type="primary" round  block @click="yulan" v-if="mes.data.State=='0' || mes.data.State=='1'">预 览</van-button>
        </div>

    </div>
     <van-image-preview
        v-model:show="state.show"
        :images="state.images"
        @change="onChange"
        :closeable="state.closeable"
        >
    </van-image-preview>
    <!-- 遮罩loading -->
    <van-overlay :show="mes.showload" @click="mes.showload = false">
        <van-loading type="spinner" color="#fff" size="50px"  style="display: flex;align-items: center;justify-content: center;height: 100%;" />
    </van-overlay>

</template>

<script>
    import {defineComponent, getCurrentInstance, onMounted, reactive} from 'vue'
    import {Dialog, Toast } from 'vant';
    import request from '@/api/invoice.js'
    import common from '@/utils/common.js'
    import fplx from '@/assets/json/fplx.json'
    import fpzt from '@/assets/json/fpzt.json'


    export default defineComponent({
        name: "invoiceDetail",
        setup(){
            const {proxy} = getCurrentInstance();
            const id = proxy.$root.$route.params.id;
            const item = proxy.$root.$route.params.item === undefined? {}: JSON.parse(proxy.$root.$route.params.item);
            const mes = reactive({
                data:{},
                showload:false
            });
            mes.data = item;
            console.log(mes);
            const state = reactive({
                show: false,
                index: 0,
                images:[
                    'https://img.yzcdn.cn/vant/apple-1.jpg',
                    'https://img.yzcdn.cn/vant/apple-2.jpg',
                ],
                closeable: true,
            });
            const onChange = (index) => {
                state.index = index;
            };
            const getfplxmc = (value) => {
                console.log(value);
                let obj = common.getObjfromArrByKey(fplx, value)
                return obj.text;
            };
             const getfpztByStatues = (item)=>{
                let obj = common.getObjfromArrByKey(fpzt, item);
                return obj["text"];
            };
            const shuaxin = () => {
                mes.showload=true;
                if (id !== undefined){
                    request.fp_cx_list({id}).then(res=>{
                        mes.showload=false;
                        console.log(res);
                        mes.data=res.rows[0];
                        // console.log(mes);
                        Toast(res.returnMessage)
                    }).catch(err=>{
                        mes.showload=false;
                        console.log('err=>',err);   
                    });
                }
            };
            const yulan = () => {
                mes.showload=true;
                if (id !== undefined){
                    request.fp_yl({id}).then(res=>{
                        console.log(res);
                        mes.showload=false;
                        if(res.returnCode=='00'){
                            state.images=[];
                            for(var i=0;i<res.rows.length;i++){
                                res.rows[i] = 'data:image/png;base64,'+res.rows[i];
                            }
                            console.log(res.rows);
                            state.images=res.rows;
                            state.show=true;
                        }else{
                            state.show=false;
                            Dialog.alert({
                                title: '温馨提示',
                                message: res.returnMessage,
                            }).then(() => {});
                        }
                        
                    }).catch(err=>{
                        mes.showload=false;
                        console.log('err=>',err);   
                    });
                }
            };
            const riqizh  = (date) => {
                console.log(date);
                if(date!="" && date!=undefined){
                    if(date.length==14){

                    var nian = date.substr(0,4);
                    var yue = date.substr(4,2);
                    var ri = date.substr(6,2);
                    var shi = date.substr(8,2);
                    var fen = date.substr(10,2);
                    var miao = date.substr(12,2);
                    var zong  = nian+"-"+yue+"-"+ri+" "+shi+":"+fen+":"+miao;
                    console.log(zong);
                    return zong
                    }
                }
            };
            onMounted(()=>{
                // shuaxin();
            });
            return{
                mes,
                getfplxmc,
                state,
                onChange,
                shuaxin,
                yulan,
                getfpztByStatues,
                riqizh
            }
        }
    });
</script>

<style  scoped>
    .main{
        background-image: url('../../assets/img/kpbj@3x.png');
        background-repeat:  no-repeat;
        background-size:100% 22%;
        margin: 0;
        padding:70px 10px 20px;
        /* background: #eee; */
        /* min-height: 100vh; */
    }
    .bjtiao{
        background: #1B75D5;
        border-radius: 10px;
        height: 15px;
    }
    .invoice{
        background: white;
        /* padding: 10px; */
        position: relative;
        box-shadow:0 5px 5px -1px #eee;
        border:1px solid #eee;
        border-radius: 0 0 10px 10px;
        margin: -4px 10px 10px;
    }
    .titleContext{
        text-align: center;
    }
    .title{
        /* border-bottom: 4px double black; */
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #1989fa;
    }
    .invoiceContent{
        margin-top: 20px;
        
    }
    .qjListItem {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 10px;
        text-align: right;
    }
    .spanTitle {
        color: #999;
        min-width: 80px;
        text-align: left;
    }
    .imgContent{
        position: absolute;
        right: 25%;
        bottom: 0;
        /* z-index: 3; */
    }
    .btnContent{
        text-align: center;
        margin-top: 60px;
        
    }
    
    .actionBtn{
        margin-top: 20px;
    }
</style>